/* import __COLOCATED_TEMPLATE__ from './import-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { next, later } from '@ember/runloop';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import openCenteredWindow from 'embercom/lib/open-centered-window';

export default Component.extend({
  pubsub: service(),
  permissionsService: service(),
  router: service(),
  styledForModal: true,
  app: null,

  openImportWindow(type) {
    let url = this.router.urlFor(`apps.app.import.${type}`);
    let window = openCenteredWindow(url, 800, 1280, 'Import');

    this.waitForChildWindow(window);
    window.focus();
    this.set('csvImportWindowCurrentlyOpen', true);
  },

  reloadUserList(shouldTransitionToSummary) {
    return this.app.reload().then(() => {
      let hasSettingsPermission = this.permissionsService.currentAdminCan(
        'can_access_product_settings',
      );
      if (shouldTransitionToSummary && hasSettingsPermission) {
        this.router.transitionTo('apps.app.settings.imports');
      } else {
        this.router.transitionTo('apps.app.users.segments.segment', 'all');
      }
      next(this, function () {
        this.pubsub.publish('reloadUserOrCompanyList');
      });
    });
  },

  waitForChildWindow(childWindow) {
    if (childWindow.closed) {
      if (window.importSuccessful) {
        this.reloadUserList(window.shouldTransitionToSummary);
        this.closeModal();
      }
      this.set('csvImportWindowCurrentlyOpen', false);
    } else {
      later(this.waitForChildWindow.bind(this, childWindow), 1000);
    }
  },

  openCsvImportInNewWindow: action(function () {
    this.openImportWindow('csv');
  }),

  openMailchimpImportInNewWindow: action(function () {
    this.openImportWindow('mailchimp');
  }),

  openMixpanelImportInNewWindow: action(function () {
    this.openImportWindow('mixpanel');
  }),
});
